<template>
  <v-layout row wrap class="d-flex justify-center">
    <v-flex xs12 md4 v-if="status == 'SETTLED'" class="text-center">
      <h1>
        <v-icon style="font-size: 64px" class="primary--text">mdi-check-decagram</v-icon>
      </h1>
      <h4 style="text-transform: capitalize; text-align: center" class="mt-10">
        Booking Confirmed
      </h4>
      <p class="" style="color: #fff; text-align: center" >
        Your payment has been verified and booking has been confirmed. You'll
        recieve the booking confirmation on email shortly...
      </p>

      <v-divider></v-divider>
      <div style="margin-top: 30px" >
        <div style="display: flex; justify-content: space-between">
          <p>Amount</p>
          <p style="font-weight: 700; font-size: 14pt">
            {{ order.paymentInfo.paidAmount.amount }} AED
          </p>
        </div>
        <div style="display: flex; justify-content: space-between">
          <p>Booking ID</p>
          <p>{{ order.merchantOrderNo.split("_")[0] }}</p>
        </div>

        <div style="display: flex; justify-content: space-between">
          <p>Method</p>
          <p>
            {{ order.paymentInfo.cardInfo.brand }} ({{
              order.paymentInfo.cardInfo.last4
            }})
          </p>
        </div>
        <v-divider></v-divider>
        <div class="mt-10"
          style="display: flex; align-items: center; justify-content: center"
        >
        
        <v-btn class="primary black--text" large @click="home">DONE</v-btn>
        </div>
      </div>
    </v-flex>
  </v-layout>
</template>

<script>
import Axios from "axios";
import { BASE_URL } from "../../config";
export default {
  data() {
    return {
      status: "",
      order: {},
    };
  },
  mounted() {
    this.verifyPayment();
  },
  methods: {
    home(){
      this.$router.push('/')
    },
    async verifyPayment() {
      let url =
        BASE_URL + "/payment/" + this.$route.query.merchantOrderNo + "_01";
      let { data } = await Axios.get(url);
      console.log(JSON.stringify(data));
      this.status = data.body.body.acquireOrder.status;
      this.order = data.body.body.acquireOrder;
      if (this.status == "PAID_SUCCESS" || this.status == "SETTLED") {
        const payload = {
          merchant_order_no: this.$route.query.merchantOrderNo + "_01",
        };
        const url = BASE_URL + "/booking/payment/verify/agent";
        const { data } = await Axios.post(url, payload);
        console.log(JSON.stringify(data));
      }
    },
  },
};
</script>